[class*="icon-"] {
	@extend .fa;
	font-size: 1.3rem;
}

.icon-menu {
	@extend .fa-bars;
}

.icon-menu-close, .icon-close {
	@extend .fa-times;
}

.icon-cart {
	@extend .fa-shopping-cart;
}

.icon-howto {
	@extend .fa-youtube-play;
}

.icon-shipment {
	@extend .fa-truck;
}

.icon-contact {
	@extend .fa-headphones;
}

.icon-account {
	@extend .fa-user;
}

.icon-faq {
	@extend .fa-question;
}

.icon-about {
	@extend .fa-smile-o;
}

.icon-left {
	@extend .fa-chevron-left;
}

.icon-previous {
	@extend .fa-angle-left;
}

.icon-start {
	@extend .fa-angle-double-left;
}

.icon-next {
	@extend .fa-angle-right;
}

.icon-last {
	@extend .fa-angle-double-right;
}

.icon-right {
	@extend .fa-chevron-right;
}

.icon-down {
	@extend .fa-chevron-down;
}

.icon-left-quote {
	@extend .fa-quote-left;
}

.icon-right-quote {
	@extend .fa-quote-right;
}

.icon-facebook {
	@extend .fa-facebook;
}

.icon-twitter {
	@extend .fa-twitter;
}

.icon-gplus {
	@extend .fa-google-plus;
}

.icon-instagram {
	@extend .fa-instagram;
}

.icon-pinterest {
	@extend .fa-pinterest;
}

.icon-youtube {
	@extend .fa-youtube;
}

.icon-star {
	@extend .fa-star;
}

