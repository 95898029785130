@import '../../components/fonts/icons/scss/font-awesome.scss';
// Base
@import 'partials/_base/_w3',
		'partials/_base/_mixins',
		'partials/_base/_config',
		'partials/_base/_icons',
        'partials/_base/_functions',
        'partials/_base/_animate';
        
//fonts
@import '../../components/fonts/raleway/raleway.scss';
@import '../../components/fonts/museo-sans/museo-sans.scss';
@import '../../components/fonts/montserrat/montserrat.scss';

.content-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}

.page-container {
	position: relative;
	display: block;
	width: 100%;
	
	@media screen and (max-width: 480px) {
		padding: 0 $padding-small;
	}

	@media screen and (min-width: 481px) and (max-width: 840px) {
		padding: 0 2.2vw;
	}

	@media screen and (min-width: 841px) and (max-width: 1280px) {
		padding: 0 6vw;
	}

	@media screen and (min-width: 1281px) and (max-width: 1600px) {
		padding: 0 8vw;
	}

	@media screen and (min-width: 1601px) and (max-width: 2600px) {
		padding: 0 11vw;
	}
	
	@media screen and (min-width: 2601px) {
		padding: 0 15vw;
	}
}

[class*="icon-"] { 
	@extend .fa; 
	
	@media screen and (max-width: 800px) {
		font-size: 1rem;
	}
}

.icon-menu { @extend .fa-bars; font-size: 200%; }

.icon-menu-close { @extend .fa-times; }

.icon-mail { @extend .fa-envelope; }

.icon-cart { @extend .fa-shopping-cart; }

.icon-info { @extend .fa-info-circle; }

.icon-phone { @extend .fa-phone; }

.icon-write { @extend .fa-pencil; }

.icon-right { @extend .fa-chevron-right; }

.header {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: $base-text;
	color: #ffffff;
	
	.logo {
		display: inline-block;
		width: 22%;
		margin-right: 2.9%;
		padding-top: $padding-small;
		padding-bottom: $padding-small;
		
		@media screen and (max-width: 840px) {
			width: 100%;
			margin: auto;
		}
		
		.logo-image {
			width: 100%;
			height: auto;
			
			@media screen and (max-width: 840px) {
				width: 40%;
				height: auto;
			}
		}
	}
	
	.menu-icon {
		float: right;
		color: #ffffff;
		cursor: pointer;
		
		@media screen and (min-width: 801px) {
			display: none;
		}
	}
	
	.menu-links-wrapper {
		position: relative;
		display: inline-block;
		width: 74%;
		padding: $padding-small 0;
		
		@media screen and (max-width: 800px) {
			display: none;
			width: 100%;
			margin: auto;
			padding: $padding 0;
		}
		
		.secondary-links {
			position: relative;
			width: 100%;
			margin-bottom: $padding-small/2;
			text-align: right;
			
			@media screen and (max-width: 800px) {
				@include flex-container;
				text-align: center;
			}
			
			.secondary-link {
				position: relative;
				display: inline-block;
				font-size: 65%;
				color: #ffffff !important;
				margin: auto $padding-small;
				
				@media screen and (max-width: 800px) {
					margin: auto $padding-small;
					font-size: 85%;
				}
					
				.badge {
					position: absolute;
					top: -0.65*$padding-small;
					right: -$padding-small;
					width: 1.3rem;
					height: 1.3rem;
					background-color: $corp-color1;
					border-radius: 50%;
					line-height: 1.3rem;
					text-align: center;
					
					@media screen and (max-width: 800px) {
						top: -0.5*$padding-small;
						right: -$padding-small/2;
						width: 1rem;
						height: 1rem;
						line-height: 1rem;
					}
				}
			}
		}
		
		.primary-links {
			position: relative;
			width: 100%;
			text-align: right;
			
			@media screen and (max-width: 800px) {
				text-align: left;
			}
			
			.primary-link {
				position: relative;
				display: inline-block;
				color: #ffffff !important;
				margin: auto $padding-small/2;
				font-family: "Raleway-bold", sans-serif;
				font-size: 85%;
				text-transform: uppercase;
				letter-spacing: 0.1rem;
				
				@media screen and (max-width: 800px) {
					width: 100%;
					margin: auto;
					padding: $padding-small/2;
				}
			}
		}
	}
}

.main-button {
	@extend .button;
	padding: $padding $padding-xlarge;
	font-family: 'Raleway-heavy', sans-serif;
	font-size: 130%;
	color: #ffffff;
	background: linear-gradient($corp-color1, #ac0322);
}

.medium-button {
	@extend .button;
	padding: $padding-large/2 $padding-large;
	font-family: 'Raleway-heavy', sans-serif;
	font-size: 110%;
	color: #ffffff;
	background: linear-gradient($corp-color1, #ac0322);
	
	@media screen and (min-width: 500px) and (max-width: 800px) {
		padding: $padding/2 $padding;
	}
}

.secondary-button {
	@extend .button;
	padding: $padding $padding;
	font-family: 'Raleway-heavy', sans-serif;
	font-size: 110%;
	color: $base-text;
	background: linear-gradient(#ffffff, #cdcdcd);
	border: 1px solid #cdcdcd;
}

.home-banner {
	position: relative;
	display: block;
	width: 100vw;
	overflow: hidden;
	
	@media screen and (min-aspect-ratio: 4/3) {
		height: 40vh;
	}
	
	@media screen and (min-aspect-ratio: 4/3) and (orientation: landscape) {
		height: 60vh;
	}
	
	@media screen and (max-aspect-ratio: 4/3) and (orientation: portrait) {
		height: 35vh;
	}
	
	@media screen and (max-aspect-ratio: 4/3) and (orientation: landscape) {
		height: 44vh;
	}
	
	.banner-image {
		width: 100%;
		height: 100%;
		
		img {
			position: relative;
			display: inline-block;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
		}
		
		.main-button {
			position: absolute;
			bottom: 3*$padding-small;
			left: 50%;
			width: 20rem;
			margin-left: -10rem;
		}
	}
}

%navigation-buttons {
	position: absolute;
	display: inline-block;
	width: 6vw;
	height: 6vw;
	top: 50%;
	margin-top: -4vw !important;
	font-size: 150%;
	color: black;
	@extend .fa;
	z-index: 9999;
	text-shadow: 2px 2px 4px rgba(255,255,255,0.22);
	cursor: pointer;
	
	@media screen and (min-width: 1100px) {
		//display: none;
	}
}

.left-arrow {
	@extend %navigation-buttons;
	left: 0;            
	@extend .fa-chevron-left;
}

.right-arrow {
	@extend %navigation-buttons;
	right: 0;
	@extend .fa-chevron-right;
}

.circle-navigation {
	position: absolute;
	@include flex-container;
	bottom: $padding;
	left: 50%;
	width: 6rem;
	margin-left: -3rem;
	z-index: 9999;
	
	.circle {
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background-color: rgba(255,255,255,0.66);
		border: 1px solid $base-text;
		cursor: pointer;
	}
	
	.active {
		background-color: rgba(0,0,0,0.8);
	}
}

.tabs-bar {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: $corp-color3;
	padding-top: $padding-xlarge;
	padding-bottom: $padding-xlarge;
	
	@media screen and (max-width: 500px) {
		padding: $padding-small/2;
	}
	
	.bar-heading {
		font-family: "Raleway-heavy", sans-serif;
		//font-size: 94%;
		text-transform: uppercase;
	}
	
	.buttons-cluster {
		position: relative;
		display: block;
	}
	
	.medium-button {
		background: #ffffff !important;
		color: #d2d2d2 !important;
		border: 2px solid #d2d2d2 !important;
		box-shadow: none !important;
		
		@media screen and (max-width: 500px) {
			display: inline-block;
			width: 100%;
			margin: $padding-small/4 0;
		}
	}
	.medium-button:hover, .active {
		color: $corp-color1 !important;
		border: 2px solid $corp-color1 !important;
	}
}

.product-tabs-bar {
	@extend .tabs-bar;
	
	.circular-buttons-cluster {
		position: relative;
		@include flex-container;
		padding: $padding-xlarge 0;
	}
	
	.circular-button {
		display: inline-block;
		margin: $padding 1vw;
		vertical-align: top;

		img {
			width: 13vw;
			height: 13vw;
			border-radius: 50%;
			border: 2px solid $corp-color1;
			
			@media screen and (max-width: 600px) {
				width: 44vw;
				height: 44vw;
			}
		}
		
		.button-text {
			display: block;
			margin: $padding-small auto;
			font-family: "Raleway-bold", sans-serif;
			text-transform: uppercase;
			color: $base-text;
		}
		
		.icon-right {
			display: inline-block;
			color: $corp-color1;
			font-size: 100%;
		}
	}
}

.home-cards-listing {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: #ffffff;
	padding-top: 2*$padding-large;
	padding-bottom: 2*$padding-large;
	
	@media screen and (min-width: 1280px) {
		padding-top: 4*$padding-large;
		padding-bottom: 4*$padding-large;
	}
	
	.card {
		position: relative;
		display: inline-block;
		margin: $padding 1vw;
		
		@media screen and (max-width: 600px) {
			width: 94%;
		}

		@media screen and (min-width: 601px) and (max-width: 840px) {
			width: 44%;
			height: 44vh;
		}

		@media screen and (min-width: 841px) and (max-width: 1280px) {
			width: 24vw;
			height: 34vw;
		}

		@media screen and (min-width: 1281px) and (max-width: 1600px) {
			width: 25vw;
			height: 35vw;
		}

		@media screen and (min-width: 1601px) and (max-width: 2600px) {
			width: 21vw;
			height: 31vw;
		}
	
		@media screen and (min-width: 2601px) {
			width: 27vw;
			height: 36vw;
		}
		
		.card-image {
			position: relative;
			width: 100%;
			height: 100%;
			background-color: $corp-color3;
			
			img {
				width: 100%;
				height: 100%;
				background-position: top center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
		
		.card-bottom {
			position: absolute;
			@include flex-container;
			bottom: 0;
			left: 0;
			width: 100%;
			//height: 4vw;
			padding: $padding/2;
			background-color: $base-text;
			color: white;
		}
	}
}

.home-testimonials {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: $corp-color2;
	color: white;
	padding-top: 2*$padding-large;
	padding-bottom: 2*$padding-large;
	
	@media screen and (min-width: 1280px) {
		padding-top: 4*$padding-large;
		padding-bottom: 4*$padding-large;
	}
	
	.section-heading {
		text-transform: uppercase;
		margin-bottom: $padding-xlarge;
	}
	
	.testimonials {
		position: relative;
		display: block;
		width: 100%;
		overflow: hidden;
	}
	
	.carousel-wrapper, .image-container {
		position: relative;
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}
	
	.category-images {
		position: relative;
		display: inline-block;
		width: auto;
		height: 22vh;
		overflow: hidden;
		
		@media screen and (min-width: 601px) and (max-width: 800px) {
			height: 26vh;
		}
		
		.category-image {
			position: relative;
			display: inline-block;
			width: 100%;
			height: 22vh;
		
			@media screen and (min-width: 601px) and (max-width: 800px) {
				height: 26vh;
			}
			
			.author-image {
				display: inline-block;
				width: 22%;
				height: 22vh;
				margin-right: 4%;
				vertical-align: top;
				
				@media screen and (max-width: 600px) {
					width: 100%;
					margin-bottom: 4%;
				}
				
				@media screen and (min-width: 601px) and (max-width: 800px) {
					width: 33%;
					margin-right: 4%;
				}
				
				img {
					width: 22vh;
					height: 22vh;
					border-radius: 11vh;
				}
			}
			
			.testimonial-text {
				display: inline-block;
				width: 60%;
				height: 22vh;
				vertical-align: top;
				
				@media screen and (max-width: 600px) {
					width: 100%;
					margin-bottom: 4%;
				}
				
				@media screen and (min-width: 601px) and (max-width: 800px) {
					width: 56%;
					height: 26vh;
					margin-right: 4%;
				}
				
				.testimonial-body {
					position: relative;
					display: block;
					height: 17vh;
					text-align: left;
					overflow-x: hidden;
					overflow-y: auto;
					text-overflow: ellipsis;
					vertical-align: top;
				}
				
				.testimonial-author {
					position: relative;
					display: block;
					height: 4vh;
					text-align: left;
					vertical-align: bottom;
				}
			}
		}
	}
}

.home-about {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: white;
	padding-top: 2*$padding-large;
	padding-bottom: 2*$padding-large;
	
	@media screen and (min-width: 1280px) {
		padding-top: 4*$padding-large;
		padding-bottom: 4*$padding-large;
	}
	
	.section-heading {
		text-transform: uppercase;
		margin-bottom: $padding-xlarge;
		text-align: center;
	}
	
	.para-intro {
		font-size: 114%;
		font-family: 'Raleway-bold';
		text-align: left;
	}
	
	.para-normal {
		text-align: left;
	}
}

.home-brands {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: $corp-color3;
	padding-top: 2*$padding-large;
	padding-bottom: 2*$padding-large;
	
	@media screen and (min-width: 1280px) {
		padding-top: 4*$padding-large;
		padding-bottom: 4*$padding-large;
	}
	
	.section-heading {
		text-transform: uppercase;
		margin-bottom: $padding-xlarge;
		text-align: center;
	}
	
	.brands, .carousel-wrapper, .image-container {
		position: relative;
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}
	
	.category-images {
		position: relative;
		display: inline-block;
		width: auto;
		height: 44vh;
		overflow: hidden;
		
		@media screen and (max-width: 800px) {
			height: 100vh;
		}
		
		.category-image {
			position: relative;
			display: inline-block;
			width: 100%;
			height: 44vh;
		
			@media screen and (max-width: 800px) {
				height: 100vh;
			}
		}
	}
	
	.two-column {
		position: relative;
		display: inline-block;
		width: 85%;
		
		.left-column {
			display: inline-block;
			width: 38%;
			margin-right: 2%;
			vertical-align: top;
			
			@media screen and (max-width: 800px) {
				width: 100%;
				margin-bottom: $padding;
			}
			
			img {
				width: 94%;
				height: auto;
			}
		}
		
		.right-column {
			display: inline-block;
			width: 58%;
			vertical-align: top;
			text-align: left;
			
			@media screen and (max-width: 800px) {
				width: 100%;
				margin-bottom: $padding;
			}
			
			.para-intro {
				font-size: 114%;
				font-family: 'Raleway-bold';
				text-align: left;
			}
	
			.para-normal {
				text-align: left;
			}
			
			.brand-link {
				text-transform: uppercase;
				font-size: 114%;
				font-family: 'Raleway-bold';
				color: $corp-color1;
			}
		}
	}
}	

.static-page, .static-section {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: white;
	padding-top: 2*$padding-large;
	padding-bottom: 2*$padding-large;
	
	@media screen and (max-width: 800px) {
		padding-top: 1*$padding;
		padding-bottom: 1*$padding;
	}
	
	@media screen and (min-width: 1280px) {
		padding-top: 4*$padding-large;
		padding-bottom: 4*$padding-large;
	}
	
	.section-heading {
		text-transform: uppercase;
		margin-bottom: $padding-xlarge;
		text-align: left;
	}
	
	.static-two-columns, .static-one-column {
		position: relative;
		display: inline-block;
		width: 100%;
		margin: 2*$padding-large auto;
		text-align: left;
		
		@media screen and (max-width: 800px) {
			margin: 1*$padding auto;
		}
		
		.column-left, .column-right {
			display: inline-block;
			margin: auto 1%;
			vertical-align: top;
		}
		
		.text-content {
			width: 56%;
			
			@media screen and (max-width: 800px) {
				width: 100%;
				margin-bottom: $padding;
			}
		}
		
		.image-content {
			width: 38%;
			
			@media screen and (max-width: 800px) {
				width: 100%;
				margin-bottom: $padding;
			}
			
			img {
				width: 94%;
				height: auto;
			}
		}
		
		.column-heading {
			text-align: left;
			margin-bottom: $padding-large;
		}
		
		.column-content {
			position: relative;
			display: inline-block;
			width: 100%;
		}
	}
}

.home-brands-bar, .brands-bar {
	position: relative;
	@include flex-container;
	background-color: white;	
	
	.brand {
		margin: $padding;
		
		img {
			width: 100%;
			height: auto;
		}
	}
}

.home-brands-bar {
	@extend .page-container;
	padding-top: 2*$padding-large;
	padding-bottom: 2*$padding-large;
	
	@media screen and (min-width: 1280px) {
		padding-top: 4*$padding-large;
		padding-bottom: 4*$padding-large;
	}
}

.product-intro-bar {
	@extend .static-section;
	background-color: $corp-color2;
	color: white;
	
	ul.product-intro-list {
		list-style-type: disc;
		text-align: left;
	}
	
	.circular-image {
		width: 22vw !important;
		height: 22vw !important;
		border: 2px solid white;
		
		@media screen and (max-width: 800px) {
			width: 100% !important;
			height: auto !important;
		}
	}
}

.multiple-product-listing {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: white;
	
	.product-div {
		@extend .static-section;
		padding-top: $padding;
		padding-bottom: $padding;
		
		.image-content, .text-content {
			width: 47% !important;
			vertical-align: top;
			
			@media screen and (max-width: 800px) {
				width: 100% !important;
			}
		}
		
		.big-image {
			position: relative;
			width: 100%;
			margin-bottom: $padding;
			
			img {
				width: 100%;
				height: auto;
			}
		}
		
		.thumbnails {
			position: relative;
			width: 100%;
			text-align: left;
			
			img.thumbnail {
				display: inline-block;
				width: 18%;
				height: auto;
				margin-right: 2%;
			}
		}
		
		.product-name {
			@extend .column-heading;
			text-transform: uppercase;
		}
		
		.product-feature {
			position: relative;
			display: block;
			margin-left: $padding-small
		}
		.product-feature::before {
			position: absolute;
			content: ".";
			top: 0;
			left: -$padding-small;
			@extend .bold;
		}
		
		.product-quantity, .product-package, label {
			display: inline-block;
			margin: auto $padding-small/2;
			
			@media screen and (max-width: 800px) {
				display: block;
				width: 100% !important;
				margin: $padding-small/2 auto;
				text-align: left;
			}
		}
		
		.product-quantity {
			width: 22%;
			padding: $padding-small/4;
		}
		
		.static-one-column {
			margin-top: 0;
		}
	}
}

.text-center { text-align: center !important; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.bold {
	font-family: "Raleway-bold", sans-serif;
}

.circular-image {
	border-radius: 50%;
}

#contact-form {
	.contact-details {
		.contact-label {
			margin: auto $padding-small;
			font-family: "Raleway-bold", sans-serif;
		}
		
		.email-id {
			color: $corp-color1;
		}
	}
	
	#message-cell {
		display: block;
		margin: $padding-small auto;
		clear: both;
	}
	
	.medium-button {
		margin: $padding-small 0;
	}
}

footer {
	position: relative;
	display: inline-block;
	@extend .page-container;
	background-color: $corp-color3;
	padding-top: $padding;
	padding-bottom: $padding;
	
	@media screen and (max-width: 800px) {
		padding: $padding-small/2;
	}
	
	.footer-links {
		position: relative;
		display: block;
		text-align: center;
		
		.footer-links-1, .footer-links-2 {
			width: 100%;
			
			.footer-link {
				display: inline-block;
				margin: auto;
				color: $base-text;
			}
			.bar {
				//content: "|";
				margin: 0 $padding-small;				
			}
			.footer-link:last-child {
				.footer-link::after {
					//content: "";
				}
			}
		}
		
		.footer-link-2 {
			margin-bottom: $padding;
		}
	}
}