@charset 'UTF-8';

// NAME: font-size
// DESCRIPTION: Calculate font-size for pixel and rem
@mixin font-size($sizeValue: 1.3) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue / 1.25 + rem;
}

@mixin row() {
    font-size: 0;
}

@mixin col($col, $sum, $gap: 1em, $align: top, $first: true, $last: true) {
    width: 100%;
    font-size: $base-font-size;
    display: inline-block;
    box-sizing: border-box;
    padding-left: if($first, 0, $gap);
    padding-right: if($last, 0, $gap);

    @media only screen and (min-width: 768px) {
        width: percentage($col/$sum);
        vertical-align: $align;
    }
}

@mixin flex-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: stretch;
    align-items: stretch;
    
    * {
    	@include flex-item;
    }
}

@mixin flex-container-inline {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: -moz-inline-flex;
    display: inline-flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

@mixin flex-item {
    margin: auto;
    -webkit-align-self: center;
    align-self: center;
}

@mixin border ($thickness, $style, $border-color, $curve-radius) {
    border-width: $thickness;
    border-style: $style;
    border-color: $border-color;
    border-radius: $curve-radius;
}

@mixin button {
    padding: $padding;
    border-radius: 0.25rem;
    text-align: center;
    text-decoration: none;
}

@mixin overlay($overlay-color, $opacity) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($overlay-color, $opacity);
}

@mixin hr($width, $height, $color) {
    width: $width;
    height: $height;
    background-color: $color;
}

@mixin diamond($width, $height, $angle:45deg) {
    width: $width;
    height: $height;
    transform: rotate($angle);
    -webkit-transform: rotate($angle);
    -ms-transform: rotate($angle);
    -moz-transform: rotate($angle);
}

@mixin triangle($bg-color, $size-w, $size-h) {
    content: "";
    position: absolute;
    display: block;
    width: $size-w;
    height: $size-h;
    background-color: $bg-color;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
}

@mixin rotated-div($angle) {
    transform: rotate($angle);
    -webkit-transform: rotate($angle);
    -ms-transform: rotate($angle);
    -moz-transform: rotate($angle);
}

@mixin gradient-linear($angle, $color1, $color2) {
    background: -webkit-linear-gradient($angle, $color1, $color2); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient($angle, $color1, $color2); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient($angle, $color1, $color2); /* For Firefox 3.6 to 15 */
    background: linear-gradient($angle, $color1, $color2); /* Standard syntax */
}

@mixin gradient-radial($shape, $color1, $color2) {
    background: -webkit-radial-gradient($shape, $color1, $color2); /* Safari 5.1 to 6.0 */
    background: -o-radial-gradient($shape, $color1, $color2); /* For Opera 11.6 to 12.0 */
    background: -moz-radial-gradient($shape, $color1, $color2); /* For Firefox 3.6 to 15 */
    background: radial-gradient($shape, $color1, $color2); /* Standard syntax */
}


@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
      @content;
    }
}

@mixin placeholder {

    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}


// NAME: col
// DESCRIPTION: Calculate width of a column. Default value of maximum columns is 12
@mixin col($col, $max-cols: 12) {
  width: (100%/$max-cols)*$col;
}


// Name: respond-to
// AUTHOR: Snugug
// SOURCE: https://gist.github.com/Snugug/2493551

// Our respond-to mixin, with the new hotness?
// A little more complicated than previous respond-to mixins, but now runs off of a variable. This is also Rev 1 so if someone can help me make it better, I'm all ears.
// We need to start with a defaulted breakpoints variable.
@mixin respond-to($context) {
  @if $breakpoints != false {
    // Check to see if the 2nd item is a number. If it is, we've got a single query
    @if type-of(nth($breakpoints, 2)) == 'number' {
      // Check to see if the context matches the breakpoint namespace
      @if $context == nth($breakpoints, 1) {
        // Call Media Query Generator
        @include media-query-gen($breakpoints) {
          @content;
        }
      }
    }
    // Else, loop over all of them
    @else {
      // Loop over each breakpoint and check context
      @each $bkpt in $breakpoints {
        // If context is correct
        @if $context == nth($bkpt, 1) {
          // Call the generator!
          @include media-query-gen($bkpt) {
            @content;
          }
        }
      }
    }
  }
}

// This functionality gets used twice so I turned it into its own mixin.
@mixin media-query-gen($bpt) {
  // Get length of breakpoint variable, minus the namespace
  $length: length($bpt);
  // Go through all of the breakpoint items, starting at the second, and add them to a variable to be passed into the media query mixin
  $mq: false !default;
  @for $i from 2 through $length {
    // If it's the first item, override $mq
    @if $i == 2 {
      $mq: nth($bpt, $i);
    }
    // Else, join $mq
    @else {
      $mq: join($mq, nth($bpt, $i));
    }
  }
  // Call Media Query mixin
  @include media-query($mq) {
    @content;
  }
}

// Slightly modified version of my Media Query Mixin (https://gist.github.com/2490750) from earlier, modified to accommodate list input
@mixin media-query($value, $operator: 'min-width', $query: 'screen') {
  // If a list is passed in for value, break it into value, operator, and query
  @if type-of($value) == 'list' {
    $mq: $value;

    @for $i from 1 through length($mq) {
      @if $i == 1 {
        $value: nth($mq, 1);
      }
      @else if $i == 2 {
        $operator: nth($mq, 2);
      }
      @else if $i == 3 {
        $query: nth($mq, 3);
      }
    }
  }

  @media #{$query} and (#{$operator}: #{$value}) {
    @content;
  }
}