@font-face {
  font-family: 'montserrat-black';
  src: url('../components/fonts/montserrat/montserrat-black.ttf');
  src: url('../components/fonts/montserrat/montserrat-black.otf'),
  	   url('../components/fonts/montserrat/montserrat-black.woff') format('woff'),
       url('../components/fonts/montserrat/montserrat-black.woff2') format('woff'),
}

@font-face {
  font-family: 'montserrat-bold';
  src: url('../components/fonts/montserrat/montserrat-bold.otf');
  src: url('../components/fonts/montserrat/montserrat-bold.woff') format('woff'),
       url('../components/fonts/montserrat/montserrat-bold.woff2') format('woff'),
}

@font-face {
  font-family: 'montserrat-semibold';
  src: url('../components/fonts/montserrat/montserrat-semibold.otf');
  src: url('../components/fonts/montserrat/montserrat-semibold.woff') format('woff'),
       url('../components/fonts/montserrat/montserrat-semibold.woff2') format('woff'),
}