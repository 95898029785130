@charset 'UTF-8';

// Color-Scheme

$base-bg: #ffffff;
$base-text: #353535;

$corp-color1: #d5042a; //red
$corp-color2: #005dab; //blue
$corp-color3: #e7e7e7; //gray


$padding: 1.25rem;
$padding-small: 1rem;
$padding-smaller: 0.65rem;
$padding-smallest: 0.4rem;
$padding-large: 1.64rem;
$padding-xlarge: 2.20rem;

$base-font-size: 13px;

.base-font {
	font-family: "Raleway", "Helvetica Neue", sans-serif;
}


body {
	max-width: 100vw;
	min-height: 100vh;
	margin: 0;
	background-color: $base-bg;
	background-repeat: repeat;
	color: $base-text;
	@extend .base-font;
	font-size: $base-font-size;
	font-weight: 300;
	line-height: 1.456789;
	text-align: center;
	overflow-x: hidden;
	
	@media screen and (min-width: 1300px) {
		font-size: 15px;
	}

	@media screen and (max-width: 340px) {
		font-size: 11px;
	}
}

* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;

	//transition: all ease 0.4s;
	//-webkit-transition: all ease 0.4s;
	//-moz-transition: all ease 0.4s;
	//-o-transition: all ease 0.4s;
}

.clearfix {
	clear: both;
}

a, a:active, a:visited, a:focus {
	color: white;
	text-decoration: none;

	&:hover {
		color: white;
	}
}

h1, h2 {
	font-family: "Raleway-heavy", sans-serif;
	line-height: 1.25;
}

h3, h4, h5, h6 {
	font-family: "Raleway-bold", sans-serif;
	line-height: 1.25;
}

h1 {
	font-size: 3.8rem;
	
	@media only screen and (max-width: 980px) {
		font-size: 2.2rem;
	}
}
h2 {
	font-size: 3.3rem;
	
	@media only screen and (max-width: 980px) {
		font-size: 2rem;
	}
}
h3 {
	font-size: 1.9rem;
	
	@media only screen and (max-width: 980px) {
		font-size: 1.7rem;
	}
}
h4 {
	font-size: 1.5rem;
	
	@media only screen and (max-width: 980px) {
		font-size: 1.5rem;
	}
}
h5 {
	font-size: 1.3rem;
	
	@media only screen and (max-width: 980px) {
		font-size: 1.3rem;
	}
}
h6 {
	font-size: 1.12rem;
	
	@media only screen and (max-width: 980px) {
		font-size: 1.1rem;
	}
}

p {
	//margin-bottom: $padding/2 !important;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="checkbox"]:focus, input[type="search"]:focus, select:focus, input[type="number"]:focus, 
input[type="text"]:hover, input[type="email"]:hover, input[type="password"]:hover, input[type="checkbox"]:hover, input[type="search"]:hover, select:hover, input[type="text"]:hover,
input[type="text"]:active, input[type="email"]:active, input[type="password"]:active, input[type="checkbox"]:active, input[type="search"]:active, select:active,
input[type="text"]:active,
textarea:focus, textarea:hover, textarea:active {
	background-color: #f4f4f4;
	outline: none;
	border: 1px solid $corp-color1;
}

input[type="text"], input[type="email"], input[type="password"], input[type="search"], input[type="number"], textarea {
	width: 83%;
	padding: $padding-small $padding;
	color: $base-text;
	@include border(1px, solid, $corp-color3, 0);
	
	@include placeholder {
		color: $corp-color3;
		font-style: italic;
	}
}

#captcha_code {
	display: inline-block;
	width: 40%;
	margin: auto $padding-small/2;
}

input[type="checkbox"], input[type="radio"] {
	padding: $padding/2;
	color: $base-text;
	@include border(1px, solid, $corp-color3, 0);
}

select {
	padding: $padding-small;
	font-size: 100%;
	line-height: 2;
	color: $base-text;
	@include border(1px, solid, $corp-color3, 0);
}

button, .button {
	display: inline-block;
	margin: $padding-small;
	border-radius: 2px;
	box-shadow: 0 3px 3px 0 rgba(0,0,0,0.2);
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

%message {
	display: inline-block;
	width: 80%;
	margin: $padding auto $padding-small auto;
	padding: $padding-small/2;
	text-align: center;
	color: $corp-color3;
}

.info_message {
	@extend %message;
	background-color: $corp-color2;
	border: 1px solid $corp-color2;
}

.error_message {
	@extend %message;
	background-color: $corp-color1;
	border: 1px solid $corp-color1;
}

.success_message {
	@extend %message;
	background-color: $corp-color2;
	border: 1px solid $corp-color2;
}

.form-group {
	margin: $padding-small auto;
	text-align: left;

	label {
		display: block;
		text-align: left;
	}
}

.form-group-with-button {
	@extend .form-group;
	
	input[type="text"], input[type="email"], input[type="password"], input[type="search"], textarea {
		width: 83%;
		padding: $padding 4*$padding $padding $padding;
	}
}

.captcha-div {
	@include flex-container;
	padding: 0 2%;
}

table {
	tr {
		border-bottom: 1px solid $corp-color1;

		td {
			padding: $padding-small/2 0;
		}
	}
	tr:last-child {
		border-bottom: 0;
	}
}

.mrindia {
	display: none;
}
